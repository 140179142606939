export const data = [
    {
        id: "0",
        pageName: "Etusivu",
        url: "/",
        heroImageUrl: "/images/koivu.jpg",
        contentText: "<p>Nälkämaa on panimoprojekti, jonka tarkoitus on tuoda nykyistä enemmän maukasta olutta etenkin kajaanilaisten ja kainuulaisten ulottuville, muuta Suomea unohtamatta.</p><br /><p>Omaa kaupallisen oluen tuottamisen mahdollistavaa panimolaitteistoa meillä ei ole, vaan toimimme kiertolaispanimo -periaatteella.</p><br /><p> Debyyttituotoksemme Luotikuja IPA valmistettiin Hopping Brewsters -panimolla Pirkanmaalla.<p><br /><p> Tähkäpäästä tuoppiin! Aleksi ja Sami</p>",
    }, 
/**     {
        id: "1",
        pageName: "Nälkämää",
        url: "/nalkamaa",
        heroImageUrl: "/images/ohra.jpg",
        contentText: "Olemme molemmat kotoisin Kajaanista ja rakkaimmat muistomme liittyvät siellä vietettyyn lapsuuteen ja nuoruuteen. Opiskelu ja työelämä toivat meidät Tampereen kautta tänne Lempäälään. Olemme kaikin tavoin innokkaita olutharrastajia. Täysmallasoluen valmistus itse mäskäämällä on ollut luontevaa jatkoa intohimoiselle harrastukselle. Meitä yhdistävä tekijä on vahva kotiseuturakkaus. Ajatuksissa ja keskusteluissa kotiseutu vilahtelee säännöllisesti, vaikka Kajaanissa ei ehdi käydä niin usein kuin haluaisi. Aleksin 2-vuotias tytärkin tapailee jo sujuvasti Nälkämaan laulun säkeitä!Nälkämaa on panimoprojekti, jonka tarkoitus on tuoda nykyistä enemmän maukasta olutta etenkin kajaanilaisten ja kainuulaisten ulottuville, muuta Suomea unohtamatta. Omaa kaupallisen oluen tuottamisen mahdollistavaa panimolaitteistoa meillä ei ole, vaan toimimme kiertolaispanimo -periaatteella. Debyyttituotoksemme Luotikuja IPA valmistettiin Hopping Brewsters -panimolla Pirkanmaalla. Tähkäpäästä tuoppiin! Aleksi ja Sami",
    },
    {
        id: "2",
        pageName: "Oluet",
        url: "/oluet",
        heroImageUrl: "/images/kuusi.jpg",
        contentText: "Oluet",
    },
    {
        id: "3",
        pageName: "Kotilaitteisto",
        url: "/laitteisto",
        heroImageUrl: "/images/kuusiUsva.jpg",
        contentText: "Kotilaitteisto",
    },*/
    {
        id: "4",
        pageName: "Yhteydenotto",
        url: "/yhteydenotto",
        heroImageUrl: "/images/kaarna.jpg",
    contentText: "<h3>Yhteydenotto</h3><a href='mailto:nalkamaaoluet@gmail.com' >nalkamaaoluet@gmail.com</a><br /><a href='https://www.facebook.com/nalkamaa'>Facebook</a>",
    },
];