import {data} from "./data"
import './App.css';
import {Nav} from "./components/Nav";
import {Hero} from "./components/Hero";
import {Content} from "./components/Content";
import {useState}  from "react";
import { useLocation } from "react-router-dom";


function App() {
  const [currentPage, setCurrentPage] = useState(0);
  const location = useLocation();
  let page = data.find(item => item.url === location.pathname);
  return (

    <div className="App">
       <header className="bg-white shadow">
        <Nav currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </header>
        <Hero title={page["pageName"]} imageUrl={page["heroImageUrl"]} />

        <Content contentText={page["contentText"]}  />
    </div>
  );
}

export default App;