export function Content({contentText}) {
    return (
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {/* <!-- Replace with your content --> */}
          <div className="px-4 py-6 sm:px-0">
                <div className="text-2xl" dangerouslySetInnerHTML={{__html: contentText}} />
          </div>
          {/* <!-- /End replace --> */}
        </div>
      </main>
    );
  }
  export default Content;