export function Hero({title, imageUrl}) {
    return (
        <div>
        <div >
          <img className="h-96 object-cover max-w-full w-full" src={imageUrl} />
        </div>
      </div>
   
    );
  }
  export default Hero;